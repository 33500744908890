/** @format */

import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { HTMLContent } from "../components/Content"
import ImageInset from "../components/ImageInset"
import HyperLinkText from "../components/HyperLinkText"

// eslint-disable-next-line
export const TheNewFlagPageTemplate = ({ title }) => {
  return (
    <section className="section">
      <div className="container">
        <div className="columns is-multiline is-centered">
          <div className="column is-8 is-6-desktop">
            <h2 className="mb-5 is-size-1 is-size-3-mobile has-text-weight-bold">
              {title}
            </h2>
            <p className="subtitle has-text-grey mb-5">
              Drawing elements from the historic{" "}
              <HyperLinkText link={"/background"} text={"flag of 1960"} /> and
              alluding to Raleigh’s famous <em>City of Oaks</em> moniker, the
              proposed new design is a classic emblem that recalls our past and
              inspires us toward a bold future.
            </p>
          </div>

          <div className="column is-12 is-10-desktop mb-5">
            <div className="columns is-multiline is-vcentered">
              <div className="column is-6 is-4-desktop mr-auto">
                <img
                  className="image is-fullwidth"
                  src="/img/ral_flag_production.png"
                  alt=""
                />
              </div>
              <div className="column is-6 is-8-desktop">
                <div className="content is-medium">
                  <ul>
                    <li>
                      The shield shape with the silver diamonds incorporates Sir
                      Walter Raleigh’s coat of arms into the acorn.
                    </li>
                    <li>
                      The design preserves the colors from the coat of arms and
                      the historic flag of 1960.
                    </li>
                    <li>
                      The acorn emblem alludes to Raleigh’s byname{" "}
                      <em>City of Oaks</em>. The acorn is a classic symbol of
                      potential and growth.​
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              The flag follows the{" "}
              <HyperLinkText
                link={"/design-principles"}
                text={"principles of good flag design"}
              />
              , making it an attractive, effective, and memorable symbol. Its
              single iconic emblem makes the flag easy to recognize even when
              it’s flapping in a breeze at a distance.
            </p>
            <h2 className="mb-4 is-size-3 is-size-3-mobile has-text-weight-bold">
              The Coat of Arms Explained
            </h2>
            <p className="subtitle has-text-grey mb-5">
              In heraldry, a coat of arms is specified by a <em>blazon</em>,
              which is a textual description of the artistic design of a shield.
              The blazon for Sir Walter Raleigh’s coat of arms is:
            </p>
            <p className="subtitle has-text-grey mb-5">
              <em>Gules, five fusils in bend argent</em>
            </p>
            <p className="subtitle has-text-grey mb-5">
              In plain English, this means “A red shield with five silver
              diamonds arranged in a diagonal band running from the top left to
              the bottom right.”
            </p>
          </div>

          <div className="column is-10 is-8-desktop mb-5">
            <div className="box has-background-light">
              <div className="is-vcentered columns is-multiline">
                <div className="column is-4">
                  <img
                    className="image is-fullwidth"
                    style={{ objectFit: "cover" }}
                    src="/img/new-flag6.png"
                    alt=""
                  />
                </div>
                <div className="column is-8">
                  <p className="is-size-5">
                    Illustration of Sir Walter Raleigh’s coat of arms​
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              The illustration, or <em>emblazonment</em>, of a coat of arms must
              follow the design specified in the blazon, but the precise style
              of the objects in the coat of arms can differ from one
              emblazonment to the next depending on the whims of the artist.
            </p>
          </div>

          <ImageInset
            figureText={
              "Portrait of Sir Walter Raleigh, with his coat of arms depicted on the tapestry at top left."
            }
            images={[
              "/img/newflag_portrait.jpg",
              "/img/newflag_portraitzoom.png",
            ]}
          />

          <div className="column is-8 is-6-desktop">
            <p className="subtitle has-text-grey mb-5">
              In the historic Raleigh flag of 1899, Sir Walter Raleigh’s coat of
              arms is emblazoned as a nearly rectangular shield with dark grey
              diamonds. In the new Raleigh flag, Sir Walter’s coat of arms is
              emblazoned in a rounded shape, merging the arms with Raleigh’s
              classic acorn emblem. The new design uses a light grey hue, which
              is truer to the original silver color in the coat of arms.
            </p>
            <h2 className="mb-4 is-size-3 is-size-3-mobile has-text-weight-bold">
              Adaptability
            </h2>
            <p className="subtitle has-text-grey mb-5">
              The emblem on the flag is easily adaptable and will transfer well
              to other media. See it here imagined on some cool{" "}
              <HyperLinkText
                link={
                  "https://www.zazzle.com/collections/flag_swag-119311331102562265"
                }
                text={"swag"}
              />
              :
            </p>
          </div>

          <ImageInset
            images={[
              "/img/newflag_hat.png",
              "/img/newflag_mug.png",
              "/img/newflag_shirt.png",
            ]}
          />

          <div className="column is-8 is-6-desktop">
            <h2 className="mb-4 is-size-3 is-size-3-mobile has-text-weight-bold">
              Action
            </h2>
            <p className="subtitle has-text-grey mb-5">
              The New Raleigh Flag Campaign will petition the city council to
              adopt this new design as an alternate official flag of Raleigh, to
              live alongside the flag of 1960, which will remain an official
              flag. Get involved and help fulfill the mission.
            </p>
            <a href="/get-involved" className="button is-primary">
              Get involved
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

TheNewFlagPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const TheNewFlagPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <TheNewFlagPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  )
}

TheNewFlagPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TheNewFlagPage

export const TheNewFlagPageQuery = graphql`
  query TheNewFlagPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
